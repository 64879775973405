<template>
  <v-card>
    <AboutUsHomeHeader></AboutUsHomeHeader>
    <section class="form-full-width">
      <FormAboutUsHome
        :loading="loading"
        :getAboutUsHome="getAboutUsHome"
        @beforeUpdate="updateAboutUsHome"
      ></FormAboutUsHome>
    </section>
  </v-card>
</template>

<script>
import FormAboutUsHome from '@/components/forms/FormAboutUsHome.vue'
import AboutUsHomeHeader from './AboutUsHomeHeader.vue'
import useAboutUsHome from '@/composables/useAboutUsHome'

export default {
  components: {
    FormAboutUsHome,
    AboutUsHomeHeader,
  },
  setup() {
    // Composition API
    const {
      aboutUsHome,
      loading,
      // methods
      getAboutUsHome,
      updateAboutUsHome,
    } = useAboutUsHome()

    return {
      aboutUsHome,
      loading,
      // methods
      getAboutUsHome,
      updateAboutUsHome,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
