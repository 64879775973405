<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formAboutUsHome" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="2">
            <label for="title_about">Título de Acerca de Nosotros</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="title_about"
              v-model="aboutUsHome.title"
              outlined
              dense
              placeholder="Título de Acerca de Nosotros"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="img_about">Imagen de Acerca de Nosotros</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="img_about"
              v-model="file_about"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="2">
            <label for="description_about">Contenido de Acerca de Nosotros</label>
          </v-col>

          <v-col cols="12" md="10" class="pb-0">
            <v-textarea
              id="description_about"
              v-model="aboutUsHome.description"
              outlined
              dense
              placeholder="Contenido de Acerca de Nosotro"
              rows="5"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="2">
            <label for="btn_text">Texto del Botón</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="btn_text"
              v-model="aboutUsHome.btn_text"
              outlined
              dense
              placeholder="Texto del Botón"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="btn_url">URL de Acerca de Nosotros</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="btn_url"
              v-model="aboutUsHome.btn_url"
              outlined
              dense
              placeholder="URL de Acerca de Nosotros"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="section">
              <v-row>
                <v-col cols="12" md="6">
                  <img
                    class="img-about"
                    draggable="false"
                    :src="url || loadImage(aboutUsHome.img)"
                    :alt="aboutUsHome.title"
                  />
                </v-col>
                <v-col cols="12" md="6" class="content-about">
                  <div>
                    <h1 class="title-about">{{ aboutUsHome.title }}</h1>
                    <p class="text-about">{{ aboutUsHome.description }}</p>
                    <a :href="aboutUsHome.btn_url" v-if="aboutUsHome.btn_text" class="btn"
                      ><span>{{ aboutUsHome.btn_text }}</span></a
                    >
                  </div>
                  <div></div>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    getAboutUsHome: Function,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      file_about: null,
      aboutUsHome: {},
    }
  },

  async created() {
    this.aboutUsHome = await this.getAboutUsHome()
  },

  methods: {
    validateForm() {
      return this.$refs.formAboutUsHome.validate()
    },
    reset() {
      this.$refs.formAboutUsHome.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      let DTO = { ...this.aboutUsHome }

      if (this.file_about) {
        DTO = {
          ...DTO,
          file: this.file_about,
        }
      }
      this.$emit('beforeUpdate', DTO)
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
.img-about {
  max-width: 100%;
  max-height: 450px;
}

.title-about {
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
}

.content-about {
  display: flex;
  align-items: center;
}

.text-about {
  white-space: pre-wrap;
  font-size: 14px;
}

.section {
  margin: 0 auto;
  max-width: 900px;
}

.btn {
  margin-top: 15px;
  background: #e83964;
  padding: 0.5em 1.5em;
  color: white;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.btn span {
  display: inline-block;
  position: relative;
  margin-left: 0;
}
</style>
