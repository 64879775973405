import { ref } from "@vue/composition-api";
import store from "@/store/index"

const useAboutUsHome = () => {

    // base data
    const aboutUsHome = ref(null);
    const loading = ref(false);

    // methods
    const getAboutUsHome = async () => {
        loading.value = true;
        const { data, status } = await store.dispatch("fetchAboutUsHome");
        loading.value = false;
        if (status != 200) return;
        aboutUsHome.value = data;
        return data;
    }

    const updateAboutUsHome = async (aboutUsHome) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateAboutUsHome", aboutUsHome)
        loading.value = false;
        if (status != 200 && status != 201) return;
        return data;
    }

    return {
        aboutUsHome,
        loading,
        // methods
        getAboutUsHome,
        updateAboutUsHome,
    };
}

export default useAboutUsHome;
